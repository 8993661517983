import Vue from 'vue'
import Router from 'vue-router'
import Axios from 'axios'
import store from '@/store'

Vue.use(Router)

const vsnHost = process.env.VUE_APP_VSNI_BASE
const jhiHost = process.env.VUE_APP_JHI_BASE

const router = new Router({
 
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./Home.vue'),
      meta: {
        title: 'RTDBox',
      }
    },
    {
      path: '/shortread/home',
      name: 'shortread-home',
      component: () => import('./shortread/ShortreadHome.vue'),
      meta: {
        title: 'Shortread Pipeline',
      },
      props: { 
        vsnHostBase: vsnHost,
        jhiHostBase: jhiHost
      }
   },
    {
       path: '/shortread/trimming',
       name: 'sr_trimming',
       component: () => import('./shortread/Trimming.vue'),
       meta: {
         title: 'Shortread Home',
       },
       props: { 
         vsnHostBase: vsnHost,
         jhiHostBase: jhiHost
       }
    },
    {
       path: '/shortread/mapping',
       name: 'sr_mapping',
       component: () => import('./shortread/Mapping.vue'),
       meta: {
         title: 'Mapping',
       },
       props: { 
         vsnHostBase: vsnHost,
         jhiHostBase: jhiHost
       }
    },
    {
      path: '/shortread/assembly',
      name: 'sr_assembly',
      component: () => import('./shortread/Assembly1.vue'),
      meta: {
        title: 'Assemble with Stringtie',
      },
      props: { 
        vsnHostBase: vsnHost,
        jhiHostBase: jhiHost
      }
   },
   {
    path: '/shortread/maker',
    name: 'sr_maker',
    component: () => import('./shortread/Maker.vue'),
    meta: {
      title: 'Assembly merge and quality control',
    },
    props: { 
      vsnHostBase: vsnHost,
      jhiHostBase: jhiHost
    }
 },
 {
  path: '/shortread/tracking',
  name: 'tracking',
  component: () => import('./shortread/Tracking.vue'),
  meta: {
    title: 'Job Tracking',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
{
  path: '/longread/home',
  name: 'longread-home',
  component: () => import('./longread/LongreadHome.vue'),
  meta: {
    title: 'Long Read Pipeline',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
{
  path: '/longread/preprocessing',
  name: 'lr_preproc',
  component: () => import('./longread/PreProcessing.vue'),
  meta: {
    title: 'Long Read Iso-Seq Subreads Pre-Processing',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
{
  path: '/longread/filter',
  name: 'lr_filtering',
  component: () => import('./longread/Filter.vue'),
  meta: {
    title: 'Transcript Filtering & QC',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
{
  path: '/longread/assembly',
  name: 'lr_assembly',
  component: () => import('./longread/Assembly.vue'),
  meta: {
    title: 'Transcript Assembly',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
{
  path: '/longread/additionalSource',
  name: 'lr_addSource',
  component: () => import('./longread/AdditionalSource.vue'),
  meta: {
    title: 'Integrate Additional Source',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
{
  path: '/longread/analysis',
  name: 'lr_analysis',
  component: () => import('./longread/Analysis.vue'),
  meta: {
    title: 'Functional Analysis',
  },
  props: { 
    vsnHostBase: vsnHost,
    jhiHostBase: jhiHost
  }
},
    {
      path: '/login',
      name: 'login',
      component: () => import('./Login.vue'),
      meta: {
        title: 'RTDBoxLogin',
      },
      props: { 
        vsnHostBase: vsnHost,
        jhiHostBase: jhiHost
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./components/Logout.vue'),
      meta: {
        title: 'RTDBoxLogout',
      },
      props: { 
        vsnHostBase: vsnHost,
        jhiHostBase: jhiHost
      }
    }
  ]
})


const DEFAULT_TITLE = 'RTDbox';
//router.afterEach((to, from) => {
//    document.title = to.meta.title || DEFAULT_TITLE;
//})

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
})

router.beforeEach((to, from, next) => {
    var isAuthenticated = false;
    var token = "";
    if (to.query.token !== undefined && to.query.token !== null)
    {
      token = to.query.token;
      store.commit('setToken', token);
    }
    else
    {
      token = store.getters.token;
    }
    
    if(to.name == "login" || to.name=="logout" || to.name=="home")
    {
      next();
    }
    else if(token !== null) {
      Axios.get("/auth", {
        params: {
          token
        },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          if(response.data == "failed")
          {
            isAuthenticated = false; 
            store.commit('setEmail', null);
            store.commit('setToken', null);
          }
          else
          {
            store.commit('setEmail', response.data);
            isAuthenticated = true;
          }
          if(!isAuthenticated) {
            next("/login");
          }
          else {
            next();
          }
        }.bind(this)
      )
      .catch(error => {
        console.log(error);
      });
    }
    else {
      next("/login");
    }
  })

export default router;