import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        email: null,
        token: null,
        folderID: null,
        },

    getters: {
        email: (state) => state.email,
        token: (state) => state.token,
        folderID: (state) => state.folderID,
    },

    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setFolderID(state, folderID) {
            state.folderID = folderID;
        },
    },

    plugins: [createPersistedState({key: "rtdStore"})]
})