<template>
  <div id="app">
    <div class="content">
      <b-navbar toggleable="lg" class="navbar-dark bg-info">
        <b-navbar-brand :to="{ name: 'home' }" style="margin: 5px">
          <img src="./assets/logo.png" height="60px" alt="RTDbox">
          RTDbox
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
        
        <b-navbar-nav class="align-items-center width-100">
        <b-button href="#/" variant="outline-light" style="margin: 5px">Home</b-button>
         <div v-if="token != null">
            <b-dropdown text="Short Read Pipeline" variant="outline-light" style="margin: 5px">
              <b-dropdown-item :to="{ name: 'shortread-home' }">Short Read Pipeline Home</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'sr_trimming' }">Stage 1 - Trimming</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'sr_mapping' }">Stage 2 - Mapping</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'sr_assembly' }">Stage 3 - Assembly</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'sr_maker' }">Stage 4 - Maker</b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="token != null">
            <b-dropdown text="Long Read Pipeline" variant="outline-light" style="margin: 5px">
              <b-dropdown-item :to="{ name: 'longread-home' }">Long Read Pipeline Home</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'lr_preproc' }">Stage 1 - Pre-Processing</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'lr_assembly' }">Stage 2 - Assembly</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'lr_filtering' }">Stage 3 - Filter and QC</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'lr_addSource' }">Stage 4 - Additional Source</b-dropdown-item>
              <b-dropdown-item :to="{ name: 'lr_analysis' }">Stage 5 - Functional Analysis</b-dropdown-item>
            </b-dropdown>
            <b-button :to="{name: 'tracking'}" variant="outline-light" style="margin: 5px">Track Existing Jobs</b-button>
          </div>
        </b-navbar-nav>

       
        
        <b-navbar-nav right class="align-items-center ml-auto" v-if="token != null" >
          <div class="text-light">-- {{ email }}</div>
          <b-button href="#/logout" variant="outline-light" style="margin: 5px">Log Out</b-button>
          
        </b-navbar-nav>

        <b-navbar-nav right class="ml-auto align-items-center" v-else >
          <b-button href="#/login" variant="outline-light" style="margin: 5px">Log In</b-button>
        </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      

      <router-view :key="$route.path"/>
    </div>
    <footer class="footer">
      <div id="footer-container" class="container">
      <b-row>
        <b-col>
        </b-col>
        <b-col>
          <a href="https://www.hutton.ac.uk"><img src="./assets/JHI_Horiz_RGB.png" height="50px" class="mx-auto d-block"></a>
        </b-col>
        <b-col>
        </b-col>
      </b-row>
      </div>
    </footer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "app",
  computed: {
    buildNumber() {
      return process.env.VUE_APP_BUILD_NUMBER
    },
    routerLink() {
      return process.env.VUE_APP_VSNI_BASE + "/"
    },
    ...mapGetters({email: "email", token: "token"})
  }
};
</script>

<style lang="scss">
@import '~bootswatch/dist/cerulean/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/cerulean/bootswatch';

.tooltip-inner {
   max-width: 80vw;
}

.input-row {
  align-items: center;
  justify-content: center;
  margin: 10px 5px;
  }

.input-label {
  width: 75%;
  }

.input-box {
  width: 25%;
}

html, body {
  height: 100%;
}


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.content {
  flex: 1 0 auto;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

.footer {
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  padding: 20px 0px;
}


/*.userinfo {
  color: #FFFFFFCC;
  padding: 8px, 8px;
}*/
</style>
