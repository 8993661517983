import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@mdi/font/css/materialdesignicons.min.css'
//import '@/assets/css/main.css'      //https://www.positronx.io/create-login-signup-ui-screens-in-vue-js-with-bootstrap/

import Vuelidate from "vuelidate";
import store from "./store";
Vue.config.productionTip = false

library.add(faAngleDown)
library.add(faAngleRight)

let axiosDefaults = require('axios/lib/defaults')

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(Vuelidate);

// Set base URL based on environment
let baseUrl = process.env.VUE_APP_BASE_URL

console.log(baseUrl)

axiosDefaults.baseURL = (baseUrl + "/rtdbox-servlet")

console.log("Axios URL " + axiosDefaults.baseURL)

new Vue({
  router,
  store,
  baseUrl,
  render: h => h(App),
}).$mount('#app')